import clsx from 'clsx'
import {useIntl} from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {
    ListsWidget2,
    ListsWidget3,
    ListsWidget4,
    ListsWidget5,
    ListsWidget6,
    MixedWidget10,
    MixedWidget11,
    MixedWidget2,
    MixedWidget8,
    TablesWidget10,
    TilesWidget1,
    TilesWidget2,
    TilesWidget3,
    TilesWidget4,
    TilesWidget5,
} from '../../../_metronic/partials/widgets'

const DashboardPage = () => (
  <>
    <div className='row gy-5 gx-xl-12'>
      {/* <div className='col-xxl-4'>
        <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
      </div> */}
      <div className='col-xl-12'>
        <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div>
    {/* <div className='row gy-5 gx-xl-8'>
      <div className='col-xxl-12'>
        <div className={clsx('card h-550px bgi-no-repeat bgi-size-cover')}
        style={{backgroundImage: `url("${toAbsoluteUrl('/media/xplan.jpeg')}")`}}>
        </div>
      </div>
    </div> */}
  </>
)

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
