/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { domainToASCII } from 'url';
import { toAbsoluteUrl } from '../../../helpers';
import { useCytoscapeTree } from './hook'
import "./styles.css";

type Props = {
  className: string
}

const TablesWidget10: React.FC<Props> = ({className}) => {

  const [nodes, setNodes] = React.useState(config)
  const [cross, setCross] = React.useState(true)

  let tasks:any = [{
    id:0,
    name:"Client Planning",
    shape:"barrel",
    backgroundColor:"#AAA",
    hide:false
  },{
    id:100,
    name:"Presta Data",
    shape:"round-rectangle",
    backgroundColor:"#DEDEDE",
    hide:true
  },{
    id:101,
    name:"Structured Data",
    shape:"round-rectangle",
    backgroundColor:"#DEDEDE",
    hide:true
  },{
    id:102,
    name:"Non Structured Data",
    shape:"round-rectangle",
    backgroundColor:"#DEDEDE",
    hide:true
  },{
    id:103,
    name:"Other Data",
    shape:"round-rectangle",
    backgroundColor:"#DEDEDE",
    hide:true
  },{
    id:1,
    name:"X-Data",
    shape:"ellipse",
    backgroundColor:"#fff",
    hide:true
  },{
    id:2,
    name:"X-AFI",
    shape:"diamond",
    backgroundColor:"#8dda92",
    hide:true
  },{
    id:3,
    name:"X-Solveur",
    shape:"round-tag",
    backgroundColor:"#74dffc",
    hide:true
  },{
    id:4,
    name:"",
    shape:"star",
    backgroundColor:"orange",
    hide:true
  },{
    id:200,
    name:"",
    shape:"round-rectangle",
    backgroundColor:"#fff",
    hide:false
  },{
    id:150,
    name:"GANTT",
    shape:"ellipse",
    backgroundColor:"#ccc",
    hide:false
  },{
    id:151,
    name:"WorkFlow",
    shape:"ellipse",
    backgroundColor:"#ccc",
    hide:true
  },{
    id:152,
    name:"2D Maps",
    shape:"ellipse",
    backgroundColor:"#ccc",
    hide:true
  },{
    id:180,
    name:"",
    shape:"ellipse",
    backgroundColor:"#ccc",
    hide:false
  }]

  const nodesToUpdate = {...config}

  // console.log ("data", tasks)

  // setTimeout(() => {
  //   console.log('Hello, World!')
  //   if (limit < dataArray.length){
  //     setLimit(limit +1)
  //   }
    
  // }, 1000);


  const update = () =>{
    const newNodes:any = []
    const newEdges:any = []

    tasks.map((dt:any)=>{

      let displayNode = 1
      if (!cross && dt.hide ){
        displayNode = 0
      }

      let url = 'transparent'
      if (cross && dt.id === 200){
        url = toAbsoluteUrl('/media/gold.png')
      }else if (dt.id === 200){
        url = toAbsoluteUrl('/media/silver.png')
      }

      if (cross && dt.id === 180){
        displayNode = 0
        url =  toAbsoluteUrl('/media/delay.png')
      }else if (dt.id === 180){
        url =  toAbsoluteUrl('/media/delay.png')
      }
      

      newNodes.push({
        data: {
          id: dt.id,
          type: "juristic",
          name: dt.name,
          backgroundColor: dt.backgroundColor,
          display: displayNode,
          collapse: 0,
          displayPopin:0,
          value:dt.name,
          hasWarning: 1,
          hide:dt.hide,
          finished:'notFinished',
          filtred: 0,
          shape:dt.shape,
          bgUrl:url
        }
      })
      
    })

    if (cross){

      newEdges.push({
        data: { source: 1, target: 2, label: 'Consolidation', type:'unbundled-bezier', style:'dashed' },
      })
      newEdges.push({
        data: { source: 2, target: 1, label: 'Consolidation', type:'unbundled-bezier', style:'dashed' },
      })
      newEdges.push({
        data: { source: 3, target: 4, label: 'SMART Planning', type:'taxi', style:'solid' },
      })
      newEdges.push({
        data: { source: 100, target: 1, label: '', type:'unbundled-bezier', style:'dashed' },
      })
      newEdges.push({
        data: { source: 101, target: 1, label: '', type:'unbundled-bezier', style:'dashed' },
      })
      newEdges.push({
        data: { source: 0, target: 1, label: '', type:'unbundled-bezier', style:'dashed' },
      })
      newEdges.push({
        data: { source: 102, target: 1, label: '', type:'unbundled-bezier', style:'dashed' },
      })
      newEdges.push({
        data: { source: 103, target: 1, label: '', type:'unbundled-bezier', style:'dashed' },
      })
      newEdges.push({
        data: { source: 2, target: 150, label: '', type:'taxi', style:'dashed' },
      })
      newEdges.push({
        data: { source: 2, target: 151, label: '', type:'taxi', style:'dashed' },
      })
      newEdges.push({
        data: { source: 2, target: 152, label: '', type:'taxi', style:'dashed' },
      })
      newEdges.push({
        data: { source: 152, target: 3, label: '', type:'bezier', style:'dashed' },
      })
      newEdges.push({
        data: { source: 151, target: 3, label: '', type:'bezier', style:'dashed' },
      })
      newEdges.push({
        data: { source: 150, target: 3, label: '', type:'bezier', style:'dashed' },
      })
      newEdges.push({
        data: { source: 4, target: 200, label: 'REP', type:'bezier', style:'solid' },
      })


      nodesToUpdate.cyLayout.rankSep = 150

    }else{

      newEdges.push({
        data: { source: 0, target: 150, label: '', type:'taxi', style:'dashed' },
      })
      newEdges.push({
        data: { source: 150, target: 180, label: 'Delay', type:'taxi', style:'dashed' },
      })
      newEdges.push({
        data: { source: 180, target: 200, label: 'Delay', type:'TAXI', style:'solid' },
      })
      
      // rankSep
      nodesToUpdate.cyLayout.rankSep = 400
      
    }
    
    nodesToUpdate.cyData.nodes = newNodes
    nodesToUpdate.cyData.edges = newEdges

    
    setNodes(nodesToUpdate)
  }

  useEffect(() => {
    update()
  }, [cross]);

  const [rootRef, cyFit, cyCenter, cyZoom] = useCytoscapeTree(nodes);

  const mystyle = {
    // backgroundImage:`url("${toAbsoluteUrl('/media/patterns/header-bg.jpg')}")`,
    // opacity: ".2"
  };

  return (
    <div className={`card p-5 ${className} `}>
      <div className='card-header border-0'>
        <h3 className='card-title align-items-start flex-column'>
          {/* <span className='card-label fw-bold fs-3 mb-1'>HOW it Works</span> */}
          {/* <span className='text-muted mt-1 fw-semibold fs-7'></span> */}
        </h3>
        <div className='xplan-toolbar'>
          <label className='form-check form-check-custom form-check-solid form-switch'>
            <input
              className='form-check-input w-35px h-20px'
              type='checkbox'
              checked={cross}
              onChange={() =>
                setCross(!cross)
              }
            />
            <span className='form-check-label fw-bold'>X'Plan Solution</span>
          </label>
        </div>
      </div>
      <div className='card-body p-0 bgi-position-y-bottom bgi-position-x-start bgi-no-repeat bgi-size-contain bgi-attachment-fixed' >
        <div id="cy" ref={rootRef} />
      </div>
    </div>
  )
}

const config = {
  cyLayout: {
    name: "dagre",
    rankDir: "LR",
    ranker:"network-simplex",
    nodeSep: 25,
    rankSep: 150,
    // marginx:10,
    // marginy:10,
    fit: true,
    padding: 50,
    // randomize: true,
    // springLength: edge => (this.elements.nodes.length > 1 ? console.log('yes') : console.log('no')),
    gravity: 1,
    spacingFactor: 1,
  },
  cyStyle: [
    {
      selector: "node",
      style: {
        "text-valign": "center",
        "text-halign": "right",
        "background-color":"data(backgroundColor)",
        "font-size": "9px",
        "text-wrap": "wrap",
        'width': '90px',
        'height': '90px',
        'background-opacity': '1',
        'shape': "data(shape)",
      }
    },{
      selector: "edge",
      style: {
        "curve-style": "data(type)", // taxi, bezier, segments, haystack, straight, unbundled-bezier
        width: 4,
        label: "data(label)",
        // "source-arrow-shape": "circle", //circle, diamond, triangle
        "target-arrow-shape": "triangle", //circle, diamond, triangle
        "text-background-color": "white",
        "text-background-padding": "2px",
        "line-color": "#cecece",
        "target-arrow-color": "#1da1d9",
        "z-index": "100",
        "text-wrap": "wrap",
        "text-background-opacity": "1",
        "target-distance-from-node": "2px",
        'line-style': "data(style)",
      }
    },
    {
      selector: "edge.highlighted",
      css: {
        'background-color': 'red',
        'line-color': 'red',
        'target-arrow-color': 'red',
        'transition-property': 'background-color, line-color, target-arrow-color',
        'transition-duration': '0.5s'
      }
    },
    {
      selector: "edge[label]",
      style: {
        label: "data(label)",
        "font-size": "12",
        "color": "black",
        "background-color":"orange",
        "text-halign": "center",
        "text-valign": "center"
      }
    },
    {
      selector: "edge.hover",
      style: {
        "line-color": "#000",
        "target-arrow-color": "#1da1d9",
      }
    },
    {
      selector: ':parent',
      css: {
        'border-color': 'data(parentColor)',
        // 'content': "data(label)",
        'line-color': 'black',
        'target-arrow-color': 'black',
        'source-arrow-color': 'black',
        'padding':"55px",
        'margin':'30px',
        'label': "data(id)",
        'text-valign': 'top',
        'text-halign': 'center',
        "text-wrap": "wrap",
        "text-background-opacity": "1",
        "target-distance-from-node": "2px",
        "text-background-color": "data(parentColor)",
        "text-background-padding": "5px",
        "text-background-margin": "5px",
        "background-color":"white",
        'background-opacity': '0.5',
      }
    },
    {
      selector: ":parent[collapse='1']",
      style: {
        "background-color":"orange",
        'width': '510px',
        'height': '490px',
        "border-color": "#ccc",
        'border-width':3,
        'border-style':'solid',
      }
    },{
      selector: "node[id='1']",
      style: {
        "border-color": "#272e5f",
        'border-width':5,
        'border-style':'solid',
        width:70,
        height:70
      }
    },{
      selector: "node[id='4']",
      style: {
        width:40,
        height:40,
        padding:15,
      }
    },{
      selector: "node[id='200']",
      style: {
        width:140,
        height:140,
        'background-fit': 'contain',
        'background-image': "data(bgUrl)",
        "text-valign": "bottom",
        "text-halign": "bottom",
      }
    },{
      selector: "node[id='180']",
      style: {
        width:80,
        height:80,
        'background-fit': 'contain',
        'background-image': "data(bgUrl)",
        "text-valign": "bottom",
        "text-halign": "bottom",
      }
    },{
      selector: "node[id='3']",
      style: {
        width:100,
        height:50,

      }
    },
    {
      selector: ':selected',
      css: {
        'border-color': 'black',
        'border-width':3,
      } 
    },{
      selector: "node[displayPopin=1]",
      css: {
        'border-width':0,
      }
    },{
      selector: 'edge[weight=0]',
      css: {
        'width': 5,
        'line-style': 'dashed',
        'line-color': 'red',
        'target-arrow-color': 'red',
        'source-arrow-color': 'red',
      }
    },{
      selector: 'edge.selectedEdge',
      css: {
        'width':5,
        'line-color': '#61bffc',
        'line-style': 'dashed'
      }
    },{
      selector: "node[display=0]",
      css: {
        'border-width':0,
        'background-opacity': '0',
        width:0,
        height:0
      }
    }
    
  ],
  cyData: {
    nodes: [],
    edges: []
  },
  drawMode:false,
  collapseArrs: {}
};

export {TablesWidget10}
