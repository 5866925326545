import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import cytoscape from "cytoscape";
import edgehandles from "cytoscape-edgehandles";
import compoundDragAndDrop from 'cytoscape-compound-drag-and-drop';
import klay from "cytoscape-klay";
import fcose from "cytoscape-fcose";
// import cola from "cytoscape-cola";
// import regCose from "cytoscape-cose-bilkent";
// import elk from "cytoscape-elk";
import dagre from "cytoscape-dagre";
// import spread from "cytoscape-spread";
// import cytoscapeAllPaths from 'cytoscape-all-paths';
import nodeHtmlLabel from "cytoscape-node-html-label";
// import { KTSVG } from "../../../../_metronic/helpers";
// import undoRedo from "cytoscape-undo-redo"

// var expandCollapse = require("cytoscape-expand-collapse");

// cytoscape.use(klay);
// cytoscape.use(fcose);
// cytoscape.use(cola);
// cytoscape.use(elk);
// cytoscape.use(regCose);
cytoscape.use(dagre);
// cytoscape.use(spread)

cytoscape.use(edgehandles);
cytoscape.use( compoundDragAndDrop );
// cytoscape.use( undoRedo )
// cytoscape.use( cytoscapeAllPaths ); // register extension


//register panzoom extension
if (typeof cytoscape("core", "nodeHtmlLabel") !== "function") {
  nodeHtmlLabel(cytoscape);
}

export const useCytoscape = (data, rootRef, isMount) => {

  return useMemo(() => {
    if (!isMount) {
      return null;
    }

    return cytoscape({
      container: rootRef.current,
      boxSelectionEnabled: true,
      autounselectify: false,
      wheelSensitivity: 0.2,
      pan: { x: 0, y: 0 },
      // userZoomingEnabled: true
      wheelSensitivity: 1,
      // minZoom: 0.1,
      // avoidOverlap: true,
      // zoom:1,
      layout: data.cyLayout,
      style: data.cyStyle,
      elements: data.cyData,
    });
  }, [data, rootRef, isMount]);
};

export const useTreeBuilder = (cy, data) => {
  useEffect(() => {
    if (!cy) return;
    const collapseArrs = {};
    cy.nodeHtmlLabel([
      {
        query: "node[display=1]",
        // cssClass: "cyNode",
        valign: "center",
        // halign: "left",
        valignBox: "center",
        // halignBox: "center",
        tpl: (data) => {
          return `<div class="${data.display===0 ? `test` : ``}" style="background-color: ${data.background};"><div class="cyNode">
                    <div class="nodeTitle">${data.name}</div>
                  </div>
                  
                  </div>
                  `;
        }
      }
    ]);

    // cy.$('node[weight=0]').connectedEdges().addClass("criticalEdge")

    cy.on("tap", "node", function () {

      cy.$('edge:all').removeClass("selectedEdge")
      this.connectedEdges().addClass("selectedEdge")
    });

    cy.on('unselect', function(evt){
      cy.$('edge:all').removeClass("selectedEdge")
    });

    const animateEdges = () => {
      cy.edges().style({ "line-dash-offset": 0 });
      cy.edges().animate({
        style: {
          "line-dash-offset": -300
        },
        duration: 5000,
        complete: () => animateEdges()
      });
    };

    animateEdges();

    cy.on("mouseover", "edge", function (e) {
      e.target.addClass("hover");
    });

    cy.on("mouseout", "edge", function (e) {
      e.target.removeClass("hover");
    });

    // Animating edge
    var bfs = cy.elements().bfs('#0', function(){}, true);

    var i = 0;
    var highlightNextEle = function(){
      if( i < bfs.path.length ){
        bfs.path[i].addClass('highlighted');
        i++;
        setTimeout(highlightNextEle, 1000);
      }
    };
    highlightNextEle(); // kick off first highlight

    cy.ready(() => {
      console.log("ready");
    });
  }, [cy]);
};

export const useCytoscapeTree = (data) => {
  const rootRef = useRef(null);
  const [isMount, setIsMount] = useState(false);

  useEffect(() => {
    if (!isMount && rootRef.current) {
      setIsMount(true);
    }
  }, [rootRef, isMount]);

  const cy = useCytoscape(data, rootRef, isMount);
  useTreeBuilder(cy, data);
  const cyFit = useCallback(() => cy && cy.fit(), [cy]);

  const cyCenter = (id) => {
    cy.center(id)
  } 
  
  const cyZoom = useCallback((val) => cy && cy.zoom(val), [cy]);

  return [rootRef, cyFit, cyCenter, cyZoom];
};
